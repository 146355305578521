import React from "react";
// import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/service-1.svg",
    title: "ui/ux设计",
    content:
      "熟练掌握用户界面(UI)和用户体验(UX)设计，通过深入了解用户需求和行为，创建直观、吸引人且易用的界面，提供出色的用户体验，以实现业务目标并满足用户期望。",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/service-2.svg",
    title: "后台管理页面设计",
    content:
      "熟练掌握后台管理设计，通过设计直观、高效的界面和功能，提供灵活的数据管理和操作，以帮助管理者轻松管理和监控系统，并提升工作效率和决策能力。",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/service-3.svg",
    title: "三维建模",
    content:
      "具备辅助三维建模能力，能够为界面设计提供出色的三维元素。尽管不是非常熟练，但我能够为项目带来美观和吸引人的三维界面，提升用户体验和视觉呈现。",
    color: "#05B2DC",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="技能" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
