import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Bolby",
  avatarImage: "/images/avatar-2.svg",
  content:
    "我是拥有六年视觉设计经验的UI设计师，擅长设计app界面和后台管理界面。意向在 UI 设计师、交互设计领域深耕发展。熟悉UI设计创作全流程，可结合用户及开发需求完成从设计构思到设计表达的整体过程，能够独立输出移动端 APP及 Web 端产品页面设计；对产品交互设计以及用户体验有着深刻体会，拥有良好的造型能力及色彩感，并能结合用户反馈提出合理化的版本送代及优化建议。",
};

const progressData = [
  {
    id: 1,
    title: "网页设计",
    percantage: 75,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "UI/UX 设计",
    percantage: 95,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "平面设计",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
];

const counterData = [

];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="关于我" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
