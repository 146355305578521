import React from "react";
// import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";

const allBlogs = [
  {
    id: 1,
    title: "悦程信息网站设计",
    image: "images/blog/web1/web1-fm.jpg",
    filesource: "../../blogs/1.md",
    date: "2020/09",
    author: "Jyking",
    category: "web设计",
  },
  {
    id: 2,
    title: "乐童游小程序设计",
    image: "images/blog/lty/lty-fm.jpg",
    filesource: "../../blogs/2.md",
    date: "2019/12",
    author: "Jyking",
    category: "小程序ui",
  },
  {
    id: 3,
    title: "招聘网小程序设计",
    image: "images/blog/zpw/zpw-fm.jpg",
    filesource: "../../blogs/3.md",
    date: "2020/06",
    author: "Jyking",
    category: "小程序ui",
  },
  {
    id: 4,
    title: "招聘网后台管理设计",
    image: "images/blog/htweb-1/htweb-1-fm.jpg",
    filesource: "../../blogs/4.md",
    date: "2020/08",
    author: "Jyking",
    category: "web后台",
  },
  {
    id: 5,
    title: "胜米App",
    image: "images/blog/smapp/smapp-fm.png",
    filesource: "../../blogs/5.md",
    date: "2023/06",
    author: "Jyking",
    category: "app设计",
  },
  {
    id: 6,
    title: "设计稿占位坑",
    image: "images/blog/3.svg",
    filesource: "../../blogs/6.md",
    date: "2024/01",
    author: "Jyking",
    category: "Business",
  },
  {
    id: 7,
    title: "设计稿占位坑",
    image: "images/blog/3.svg",
    filesource: "../../blogs/6.md",
    date: "2024/01",
    author: "Jyking",
    category: "Business",
  },
  {
    id: 8,
    title: "设计稿占位坑",
    image: "images/blog/3.svg",
    filesource: "../../blogs/6.md",
    date: "2024/01",
    author: "Jyking",
    category: "Business",
  },
  {
    id: 9,
    title: "设计稿占位坑",
    image: "images/blog/3.svg",
    filesource: "../../blogs/6.md",
    date: "2024/01",
    author: "Jyking",
    category: "Business",
  },
];

function Blogs() {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="作品" />
        <div className="row blog-wrapper">
          {allBlogs.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        {/* <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all blogs
          </Link>
        </div> */}
      </div>
    </section>
  );
}


export default Blogs;
