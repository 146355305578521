import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "电子科技大学中山学院",
    years: "2020 - 2023",
    content:
      "视觉传达设计专业",
  },
  {
    id: 2,
    title: "广州康大职业技术学院",
    years: "2015 - 2018",
    content:
      "环境艺术设计专业",
  },
  // {
  //   id: 3,
  //   title: "Honours Degree",
  //   years: "2013 - 2009",8
  //   content:
  //     "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  // },
];

const experienceData = [
  {
    id: 1,
    title: "UI设计师",
    years: "2021 - 至今",
    content:
      "广州胜米科技有限公司",
  },
  {
    id: 2,
    title: "UI/平面设计师",
    years: "2019-2021",
    content:
      "广州风信科技有限公司.",
  },
  // {
  //   id: 3,
  //   title: "Back-End Developer",
  //   years: "2013 - 2009",
  //   content:
  //     "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  // },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="经历" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
